<template>
  <ModalMainNew modal-width-mode="sm" @close="close(true)">
    <div class="px-1 pt-2 w-full">
      <article class="animate-fade-in">
        <div class="flex flex-col items-center">
          <div v-if="showWallet" class="max-w-[15rem]">
            <h1 class="tracking-wide font-semibold text-center animate-slide-down-fade-in01s">
              {{ title }}
            </h1>
            <div
              v-if="connectMessage || showWallet"
              class="leading-tight animate-slide-down-fade-in04s text-center mt-1"
            >
              <p class="text-sm text-[#B1BAD3]">
                {{ message }}
              </p>
            </div>
          </div>
          <div v-if="!isUserLogged || userHasAddress || connectFromEmail" class="w-full">
            <ModalConnectWallet
              v-if="showWallet && !emailSent"
              :web-data="web3"
              :providers="providers"
              :is-loading="isLoading"
              :web3-only="connectWeb3Only"
              @on-login="login"
            />
            <template v-if="!connectWeb3Only">
              <ModalConnectEmail v-if="!emailSent" @on-email-submit="emailLogin"/>
              <div v-else-if="emailSent">
                <p class="text-center leading-tight text-gray-300 text-md mt-2 mb-6 pt-2 animate-slide-down-fade-in01s">
                  We've emailed a one-time link to <b>{{ email }}</b>. Click the link to sign in or register an acccount.
                </p>
                <ButtonButton
                  :is-loading="loading"
                  :disabled="resendCooldown"
                  class="w-full"
                  size="sm"
                  @click.prevent="resendEmail"
                >
                  {{
                    resendCooldown
                      ? `Resend email in ${cooldownTimeRemaining}s`
                      : "Resend Email Verification"
                  }}
                </ButtonButton>
              </div>
            </template>
            <ModalConnectSocial
              v-if="!connectWeb3Only && !emailSent && Object.keys(web2).length > 0"
              :web-data="web2"
              :providers="providers"
              :is-loading="isLoading"
              @on-social-login="login"
            />
          </div>
          <div v-else class="pt-6 pb-4">
            <ButtonButton @click.prevent.stop="openAddAliasModal('EthereumAddress')">
              Connect
            </ButtonButton>
          </div>
          <h2
            v-show="errorMessage"
            class="w-full inline-block text-red text-sm mt-2 text-center"
          >
            {{ errorMessage }}
          </h2>
        </div>
      </article>
    </div>
  </ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useIntervalFn } from '@vueuse/core';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useWalletProviderStore } from '@/store/wallet-provider';
import { getConfigSplit } from '@/utils/getConfig';
import metaMaskLogo from '~/assets/img/logos/metamask.svg';
import coinbaseLogo from '~/assets/img/logos/coinbase.png';
import walletConnectLogo from '~/assets/img/logos/walletconnect.svg';
import googleLogo from '~/assets/img/logos/google.svg';
import facebookLogo from '~/assets/img/logos/facebook.svg';
import phantomLogo from '~/assets/img/logos/phantom.svg';

const { $gtmCustomEvent, } = useNuxtApp();

const authStore = useAuthStore();
const {
  signaturePending,
  errorMessage,
  attach,
  isUserLogged,
  address,
  attachAddress,
} = storeToRefs(authStore);
const {
  loginWallet,
  attachWallet,
  loginEmailOneTimeLink,
  loginWithSocial,
} = authStore;

const uiStore = useUiStore();
const {
  showInfoModal,
  connectNoLogin,
  connectFromEmail,
  connectWeb3Only,
  connectMessage,
  connectSwap,
  showWalletModal,
  connectChainType,
  web3LoginProvider,
} = storeToRefs(uiStore);
const { closeConnectModal, openAddAliasModal, } = uiStore;

const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);

const walletProviderStore = useWalletProviderStore();
const { disconnect, } = walletProviderStore;

const selected = ref();
const showWallet = ref(true);
const emailSent = ref(false);
const loading = ref(false);
const email = ref('');
const cooldownDuration = ref(15);
const resendCooldown = ref(false);
const cooldownTimeRemaining = ref(15);
const cooldownInterval = ref();
const providers = ref({
  metamask: {
    label: 'MetaMask',
    loading: false,
    image: metaMaskLogo,
    type: 'Web3',
    isLarge: true,
    chain: 'Ethereum',
  },
  coinbase: {
    label: 'CoinBase',
    loading: false,
    image: coinbaseLogo,
    type: 'Web3',
    chain: 'Ethereum',
  },
  walletconnect: {
    label: 'WalletConnect',
    loading: false,
    image: walletConnectLogo,
    type: 'Web3',
    chain: 'Ethereum',
  },
  phantom: {
    label: 'Phantom',
    loading: false,
    image: phantomLogo,
    type: 'Web3',
    chain: 'Solana',
  },
  google: {
    label: 'Google',
    loading: false,
    image: googleLogo,
    theme: 'google',
    buttonText: 'Continue with Google',
    type: 'Web2',
  },
  facebook: {
    label: 'Facebook',
    loading: false,
    image: facebookLogo,
    theme: 'facebook',
    buttonText: 'Continue with Facebook',
    type: 'Web2',
  },
});

const isLoading = computed(() => Object.values(providers.value).some(v => v.loading));
const userHasAddress = computed(() => !!userData.value?.aliases?.EthereumAddress || !!userData.value?.aliases?.SolanaAddress);
const userAlias = computed(() => userData.value?.aliases?.EthereumAddress || userData.value?.aliases?.SolanaAddress);
const title = computed(() => emailSent.value ? 'Sign In' : 'Connect your Web3 wallet');
const message = computed(() => emailSent.value ? 'Check your email' : connectMessage.value);
const active = computed(() => {
  return Object.keys(providers.value)
  .filter(v => getConfigSplit('AUTH_PROVIDERS').includes(v))
  .reduce((obj, key) => {
    obj[key] = providers.value[key];
    return obj;
  }, {});
});
const web3 = computed(() => {
  return Object.keys(providers.value)
  .filter((v) => {
    return userData.value?.aliases ? (!!userData.value?.aliases?.SolanaAddress && providers.value[v].chain === 'Solana') || (!!userData.value?.aliases?.EthereumAddress && providers.value[v].chain === 'Ethereum') : true;
  })
  .filter(v => active.value[v]?.type === 'Web3')
  .filter(v => !connectChainType.value || active.value[v]?.chain === connectChainType.value)
  .reduce((obj, key) => {
    obj[key] = providers.value[key];
    return obj;
  }, {});
});
const web2 = computed(() => {
  return Object.keys(providers.value)
  .filter(v => active.value[v]?.type === 'Web2')
  .reduce((obj, key) => {
    obj[key] = providers.value[key];
    return obj;
  }, {});
});

showInfoModal.value = false;
$gtmCustomEvent({
  event: 'modal_show',
  action: 'modalOpen',
  modalName: 'Connect',
});

function startCooldown() {
  if (cooldownInterval.value) {
    return;
  }

  resendCooldown.value = true;
  cooldownTimeRemaining.value = cooldownDuration.value;

  const { pause, resume, } = useIntervalFn(
    () => {
      if (cooldownTimeRemaining.value > 1) {
        cooldownTimeRemaining.value -= 1;
      } else {
        resendCooldown.value = false;
        cooldownTimeRemaining.value = cooldownDuration.value;
        pause(); // Stop the interval
        cooldownInterval.value = null; // Clear the interval reference
      }
    },
    1000,
    { immediate: true, }
  );

  cooldownInterval.value = { pause, resume, };
}

async function resendEmail() {
  try {
    loading.value = true;
    await loginEmailOneTimeLink(email.value?.toLowerCase()?.trim());
    startCooldown();
  } catch (err) {
    cooldownTimeRemaining.value = cooldownDuration.value;
    if (err?.status === 429) {
      errorMessage.value = 'Too many requests. Please try again later.';
      startCooldown();
    } else {
      errorMessage.value = err;
      resendCooldown.value = false;
    }
  } finally {
    loading.value = false;
  }
}

async function login(provider) {
  providers.value[provider].loading = true;
  selected.value = provider;
  errorMessage.value = '';
  attach.value = !!address.value || !!attachAddress.value;

  if (attach.value) {
    await attachWallet(provider, connectNoLogin.value);
  } else if (providers.value[provider].type === 'Web2') {
    await loginWithSocial(provider);
    providers.value[provider].loading = false;
  } else {
    try {
      web3LoginProvider.value = provider;
      await loginWallet(provider, connectNoLogin.value, true);
      return;
    } catch (error) {
      errorMessage.value = `Unable to connect to ${providers.value[provider].label} wallet.`;
      providers.value[provider].loading = false;
      return;
    }
  }

  if (userData.value?.aliases && (userAlias.value?.toLowerCase() !== address.value?.toLowerCase())) {
    errorMessage.value = `Incorrect wallet address selected in ${providers.value[provider].label}.`;
    return;
  }

  if (connectSwap.value) {
    showWalletModal.value = true;
  }

  if (connectFromEmail.value) {
    openAddAliasModal('EthereumAddress', 2, connectSwap.value ? 'swap' : null);
    return;
  }

  if (connectNoLogin.value) {
    close();
  }
}

function emailLogin(address) {
  emailSent.value = true;
  email.value = address;
  startCooldown();
}

function close(onDisconnect) {
  errorMessage.value = '';
  signaturePending.value = false;
  closeConnectModal();
  attach.value = false;

  if (onDisconnect) {
    disconnect();
  }
}

watch(
  () => isUserLogged.value,
  (newVal) => {
    if (newVal && !connectNoLogin.value) {
      close();
    }
  }
);

watch(
  () => errorMessage.value,
  (newVal) => {
    if (newVal && selected.value) {
      providers.value[selected.value].loading = false;
    }
  }
);
</script>
